@tailwind base;
@tailwind components;
@tailwind utilities;

/* Sidebar.css */
.sidebar {
  height: 100vh; /* Full height */
  width: 60px; /* Width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #003366; /* Dark blue background */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px; /* Some padding at the top */
}

.sidebar a {
  padding: 10px 15px; /* Padding */
  text-decoration: none; /* Remove underlines */
  font-size: 16px; /* Increase font size */
  color: white; /* White text color */
  display: block; /* Make the links appear below each other */
}

.sidebar a:hover {
  background-color: #555; /* Add a hover effect */
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pulse-button {
  animation: pulse-animation 2s infinite;
  /* Additional styling for your button */
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 9999; /* Ensure the overlay is on top of everything */
}
